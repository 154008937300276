<template>
  <div>
    <b-card>
      <b-card-header>
        <h2>
          <feather-icon icon="LayersIcon" />
          {{ $t("AppHelpDesk.newIncident") }}
        </h2>
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col cols="12">
                <form-input
                  v-model="incidentData.titulo"
                  :label="$t('AppHelpDesk.titleError')"
                  validation-rules="required|max:256"
                  :get-validation-state="getValidationState"
                  :disabled="!$can('write', 'MesaAyuda')"
                />
              </b-col>
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('AppHelpDesk.problemDescription')"
                  rules="required|max:1025"
                >
                  <b-form-group
                    :label="$t('AppHelpDesk.problemDescription')"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="incidentData.descripcion"
                      rows="3"
                      :placeholder="$t('AppHelpDesk.placeHolderIncident')"
                      :disabled="!$can('write', 'MesaAyuda')"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="float-right mt-2 mb-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="addingIncident"
                  small
                  class="mr-1"
                />
                {{ $t("Lists.Add") }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  VBTooltip,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  max,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import FormInput from '@/components/FormInput.vue'
import helpDeskService from '@/services/helpDesk.service'
import { ref } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormTextarea,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInvalidFeedback,

    FormInput,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      max,
    }
  },
  setup() {
    const addingIncident = ref(false)
    const blankIncident = {
      incidenciaId: 0,
      titulo: '',
      descripcion: '',
      fechaInicio: '',
      fechaFin: '',
    }
    const now = new Date()
    const min = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const incidentData = ref(JSON.parse(JSON.stringify(blankIncident)))
    const resetIncidentData = () => {
      incidentData.value = JSON.parse(JSON.stringify(blankIncident))
    }
    const {
      refFormObserver,
      resetForm,
      getValidationState,
    } = formValidation(resetIncidentData)

    const {
      createIncident,
    } = helpDeskService()

    const onSubmit = () => {
      addingIncident.value = true
      createIncident(incidentData.value)
        .then(() => {
          addingIncident.value = false
          router.push({ name: 'apps-tickets-incidents-list' })
        })
        .catch(() => {
          addingIncident.value = false
        })
    }
    return {
      resetIncidentData,
      onSubmit,

      min,
      addingIncident,
      incidentData,
      refFormObserver,
      resetForm,
      getValidationState,
    }
  },
}
</script>
